<template>
  <h1>Account</h1>
  <div v-if="!refreshToken">
    <p>
      Hier kannst du dich einloggen, um deine Liste zu speichern. Wenn du noch
      keinen Account hast, kannst du dich hier
      <router-link to="/register">registrieren</router-link>.
    </p>
    <form class="flexbox" @submit.prevent="submit">
      <div class="flexbox-2">
        <input
          v-model="name"
          placeholder="username"
          class="flexbox-item"
          required
          autofocus
        />
        <input
          v-model="password"
          placeholder="password"
          type="password"
          class="flexbox-item"
          required
        />
      </div>
      <button class="flexbox-item" type="submit">Einloggen</button>
    </form>
  </div>
  <div v-else class="flexbox">
    <p>Hallo {{ jwtname }}</p>
    <button class="flexbox-item">
      <router-link to="/logout">Logout</router-link>
    </button>
  </div>
</template>

<script>
import { handleAuth } from "@/mixins/handleAuth";
import jwt_decode from "jwt-decode";
export default {
  name: "Login",
  data() {
    return {
      name: "",
      password: "",
      jwtname: this.refreshToken ? jwt_decode(this.refreshToken).name : null,
    };
  },
  mixins: [handleAuth],
  methods: {
    async submit() {
      await this.login(this.name, this.password);
    },
  },
};
</script>

<style scoped>
.flexbox {
  /* max-width: 200px; */
  display: flex;
  flex-direction: column;
}
.flexbox-2 {
  /* max-width: 200px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: grey; */
}

.flexbox-item {
  margin: 0.2rem;
  padding: 0.4rem;
  flex-grow: 1;
}

button {
  border-radius: 0px;
  border: none;
  background-color: var(--primary-color);
  font-weight: bold;
  justify-content: center;
}
input {
  border-radius: 0px;
  border-style: solid;
  border-color: var(--tertiary-color);
  /* border: none; */
}

button a {
  text-decoration: none;
}
</style>
